<script>
import IconComponent from "@/components/redesign/iconComponent.vue";
import SearchComponent from "@/components/redesign/searchComponent.vue";
import SegmentedControl from "@/components/redesign/segmentedControl.vue";
import DefaultPopup from "@/components/redesign/defaultPopup.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";

export default {
  name: "FunctionsTable",
  components: {DefaultButton, DefaultPopup, SegmentedControl, SearchComponent, IconComponent},
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    visibleSettingsTable: {
      type: Boolean,
      default: true
    },
    visibleBtnAddRow: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      searchQuery: null,
      activeTab: {}
    }
  },
  watch: {
    searchQuery(value) {
      this.$emit('update:searchQuery', value)
    },
    activeTab(value) {
      this.$emit('update:activeTab', value)
    }
  },
  mounted() {
    if(!this.activeTab.id) {
      this.activeTab = this.tabs[0]
    }
  }
}
</script>

<template>
  <div class="functions">
    <div class="left">
      <search-component
          v-model="searchQuery"
      />
      <segmented-control
          :tabs="tabs"
          v-model="activeTab"
          size="medium"
      ></segmented-control>
    </div>
    <div class="right">
      <default-button
          v-if="visibleBtnAddRow"
          @click-element="$emit('add-row')"
          size="medium"
          leftIcon="/default-icons/add_circle.svg"
          width="fit-content"
      >{{$t('Добавить')}}</default-button>
      <default-popup
          v-if="visibleSettingsTable"
          :width="250"
          class="custom-style-popup"
      >
        <template v-slot:buttonContent>
          <icon-component
              :icon="'/default-icons/more_vert.svg'"
              :color="'var(--colorIconAccent)'"
              :size="20"
          />
        </template>
        <template v-slot:content>
          <slot name="content"></slot>
        </template>
      </default-popup>
    </div>
  </div>
</template>

<style scoped lang="scss">
.functions {
  .left, .right {
    display: flex;
    gap: 15px;
  }
  .right {
    gap: 5px;
    .custom-style-popup {
      &:deep(.default-popup-expand) {
        padding: 8px;
      }
    }
  }
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
</style>