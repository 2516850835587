<template>
  <div class="tooltip-wrapper">
    <div @mouseenter="openOptions" @mouseleave="closeOptions"> <!--обёртка для закрепления подсказки за элементом. События вызываются когда указательное устройство пределах элемента-->
        <slot></slot>
    </div>
    <transition name="tooltip">
      <div id="tooltip-link" class="tooltip-block" :class="[colorStyle, position]" :style="[widthContainer?{width:`${widthContainer}px`}:{}]" v-if="visibleTooltip">
        <div class="tooltip-container">
          <div class="tip-frame"> <!--указатель подсказки-->
            <svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_dd_1866_7813)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C13 0 15.9999 8 20 8H0C3.9749 8 7 0 10 0Z" :fill="colorStyle==='overlay'?'var(--colorOverlayDeep)':(colorStyle==='accent'?'var(--colorBackgroundAccentTint)':'var(--colorBackgroundContent)')"/>
              </g>
              <defs>
                <filter id="filter0_dd_1866_7813" x="0" y="0" width="20" height="8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.109804 0 0 0 0.1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_7813"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="4"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.109804 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="effect1_dropShadow_1866_7813" result="effect2_dropShadow_1866_7813"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1866_7813" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
          <div class="tip-info">
            <div class="tip-info__header" :style="[noWrap?{whiteSpace: 'nowrap'}:{}]">
              <div class="tip-info__header_title" v-if="title">{{title}}</div>
              <div class="tip-info__header_description" v-if="description">{{description}}</div>
            </div>
            <slot name="content"></slot> <!--Доп контент для подсказки. Используйте <template v-slot:content></template> -->
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * !!!Важно:
 * На странице не может быть два одновременно показанных тултипа. Они всегда должны показываться последовательно: следующий показывается при закрытии текущего и так до конца.
 *
 * Просы:
 * @prop {String} position имеет следующие состояния:
 * bottom-left, bottom-center, bottom-right, top-left, top-center, top-right
 * left-top, left-center, left-bottom, right-top, right-center, right-bottom,
 * 1. Часть означает положение всей подсказки
 * 2. Часть означает положение указателя
 *
 * @prop {String} title - содержит заголовок подсказки
 * @prop {String} description - содержит подзаголовок подсказки
 * @prop {String} colorStyle - определяет каким цветом будет подсказка, заголовок и подзаголовок, и имеет следующие состояния: default-style, overlay, accent
 * @prop {Number} widthContainer - даёт возможность жёстко задать ширину подсказки, по дефолту ширина зависит от контента
 * @prop {Boolean} noWrap - отключает перенос строк у заголовка и подзаголовка, если пропс в значении true
 * @prop {Boolean} mobile - принудительно отключает подсказки на устройствах, где только touch события, если пропс в значении false (сделано для телефонов и планшетов)
 *
 * В дальнейшем блок, на котором срабатывают события mouseenter, mouseleave, будем называть "ховер-элементом"
 */
export default {
  name: "tooltipComponent",
  props:{
    position:{
      type:String,
      default:'bottom-left'
    },
    title:{
      type:String,
      default:''
    },
    description:{
      type:String,
      default:''
    },
    colorStyle:{
      type:String,
      default:'default-style'
    },
    widthContainer:{
      type:Number,
      default:0
    },
    noWrap:{
      type:Boolean,
      default:false
    },
    mobile:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      visibleTooltip:false, //Отвечает за добавление и удаления подсказки из DOM дерева
      observer:null, //В модель помещается IntersectionObserver
      possiblePosition:null, //Объект возможных позиций подсказки (left, right, top, bottom)
      target:null,  //В модель помещается блок, на котором сработали события mouseenter,mouseleave. Она была создана для того, чтобы вызывать closeOptions без пропса (без event) в watchingChangePosition
      indentFromElement:8,  //Отступ от подсказки до ховер-элемента. Так как указатель подсказки/стрелочка имеет абсолютное позиционирование, то он не учитывается при расчётах ширины и высоты подсказки, поэтому важно сделать базовый отступ, который равен высоте указателя.
    }
  },
  methods:{
    openOptions(e){
      if(!(this.$root.touchDevice&&!this.mobile)){ //Подсказка не откроется, если устройство имеет только touch события и при этом жётско отключены подсказки на элементах с touch событиями
        this.watchingChangePosition() //Включаем отслеживанием всех скроллов на странице
        const existTooltip = document.getElementById('tooltip-link');
        if(existTooltip) existTooltip.parentNode.removeChild(existTooltip); //Если найдена не скрытая подсказка другого компонента, то её необходимо принудительно удалить.
        this.visibleTooltip=true //Отображаем подсказку, добавляем в DOM-дерево

        this.target =e.target //получаем ховер=элемент
        this.target.classList.add('tooltip-hover-el'); //добавляем класс активной подсказки на ховер-элемент, что потом отслеживать его через IntersectionObserver
        let options = { //Параметры для IntersectionObserver. Родитель -окно браузера
          rootMargin: "0px",
          threshold: 1.0,
        };

        const callback = (entries, observer) => { //callback IntersectionObserver
          entries.forEach((entry) => {
            this.determiningFinallyPosition(entry)  //Так как подсказка одна и имеет свойство position:fixed, необходимо опрделить будущую позицию этой подсказки относительно окна браузера. То есть закрепить подсказку за ховер-элементом.
            observer.unobserve(entry.target)
          });
        };

        this.observer = new IntersectionObserver(callback, options); //Создаём IntersectionObserver
        document.querySelectorAll('.tooltip-hover-el').forEach(
            p=>{this.observer.observe(p)}
        )
      }
    },
    closeOptions(){ //Закрытие подсказки
      if(this.target){ //Если определён ховер-элемент
        window.removeEventListener('scroll', this.closeOptions, true) //Удаляем событие отслеживания всех скроллов на странице
        this.target.classList.remove('tooltip-hover-el'); //Удаляем класс с ховер-элемента, который нужен для отслеживания IntersectionObserver
        this.target=null //Удаляем инфу о ховер-элементе
        this.visibleTooltip=false //Удаляем подсказку из DOM-дерева
      }
    },
    determiningFinallyPosition(entry){
      const tooltip=document.getElementById('tooltip-link'); //Получаем блок с подсказкой
      if(tooltip){ //Если подсказка создана
        const indicatorsPosition=this.position.split('-')
        const generalPosition=indicatorsPosition[0] //Общая позиция подсказки
        const pointerPosition=indicatorsPosition[1] //Расположение указателя подсказки
        this.determiningPossiblePosition(entry,tooltip,pointerPosition) //Определние возможных позиций для подсказки
        if(this.possiblePosition[generalPosition]||!Object.values(this.possiblePosition).some((el)=>el===true)) this.determiningBasePosition(entry,tooltip,generalPosition,pointerPosition) //Задаём дефолтную позицию подсказке, если она помещается в эту дефолтну позицию или не помещается ни в одну из возможных позиций
        else { //Если дефолтная позиция подсказки не помещается на экране, но есть возможные другие позиции, то происходит поиск первой доступной позиции для отображения подсказки (сверху и по часовой стрелке)
          tooltip.classList.remove(this.position); //удаляем дефолтную позицию
          if(this.possiblePosition.top){
            let finallyPointerPosition=this.changerPointerClass('vertical',pointerPosition)
            this.updatePositionTooltip(entry,tooltip,'top',finallyPointerPosition)
          }
          else if(this.possiblePosition.right){
            let finallyPointerPosition=this.changerPointerClass('horizontal',pointerPosition)
            this.updatePositionTooltip(entry,tooltip,'right',finallyPointerPosition)
          }
          else if(this.possiblePosition.bottom){
            let finallyPointerPosition=this.changerPointerClass('vertical',pointerPosition)
            this.updatePositionTooltip(entry,tooltip,'bottom',finallyPointerPosition)
          }
          else if(this.possiblePosition.left){
            let finallyPointerPosition=this.changerPointerClass('horizontal',pointerPosition)
            this.updatePositionTooltip(entry,tooltip,'left',finallyPointerPosition)
          }
        }
      }
    },
    determiningBasePosition(entry,tooltip,generalPosition,pointerPosition){
      //entry - ховер-элемент
      //tooltip - подсказка
        if(generalPosition==='top'||generalPosition==='bottom'){ //Общая позиция подсказки сверху или снизу
          let positionY=0
          if(generalPosition==='top') positionY=entry.boundingClientRect.top-tooltip.offsetHeight-this.indentFromElement
          else positionY=entry.boundingClientRect.top+entry.target.offsetHeight+this.indentFromElement
          const positionX=entry.boundingClientRect.left+entry.target.offsetWidth/2 //центральная позиция ховер-элмента по оси X. Все подсказки должны быть по центру ховер-элементов.

          if(pointerPosition==='left') tooltip.style.inset = `${positionY}px auto auto ${positionX-22}px`;  /*    22 - -это расстояние до указатели и половина его ширины (12px+10px). Общая формула {positionX-смещение указателя}*/
          else if(pointerPosition==='center') tooltip.style.inset = `${positionY}px auto auto ${positionX-tooltip.offsetWidth/2}px`;
          else if(pointerPosition==='right') tooltip.style.inset = `${positionY}px auto auto ${positionX-tooltip.offsetWidth+22}px`;
        }
        else if(generalPosition==='left' || generalPosition==='right'){ //Общая позиция подсказки слева или справа
          const positionY=entry.boundingClientRect.top+entry.target.offsetHeight/2 //центральная позиция ховер-элмента по оси Y.
          let positionX=0
          if(generalPosition==='left') positionX=entry.boundingClientRect.left-tooltip.offsetWidth-this.indentFromElement
          else positionX=entry.boundingClientRect.left+entry.target.offsetWidth+this.indentFromElement

          if(pointerPosition==='top') tooltip.style.inset = `${positionY-16}px auto auto ${positionX}px`;  /*    16 - -это расстояние до указатели и половина его высоты (12px+4px)*/
          else if(pointerPosition==='center') tooltip.style.inset = `${positionY-tooltip.offsetHeight/2}px auto auto ${positionX}px`;
          else if(pointerPosition==='bottom') tooltip.style.inset = `${positionY-tooltip.offsetHeight+16}px auto auto ${positionX}px`;
        }
    },
    determiningPossiblePosition(entry, tooltip,pointerPosition){ //Так как подсказки могут выходить за пределы экрана, то нужно определить, в каких позициях подсказка помещается в экран.
      this.possiblePosition={
        left:false,
        right:false,
        top:false,
        bottom:false
      }
      if(entry.boundingClientRect.left+entry.target.offsetWidth+tooltip.offsetWidth+this.indentFromElement<entry.rootBounds.width) this.possiblePosition.right=this.checkHorizontalCapacity(entry,tooltip,pointerPosition) //Изначально проверяем, что подсказка помещается справа, относительно окна браузера, а потом вызываем функцию для проверки сверху и снизу
      if(entry.boundingClientRect.left>tooltip.offsetWidth+this.indentFromElement)this.possiblePosition.left=this.checkHorizontalCapacity(entry,tooltip,pointerPosition) //Изначально проверяем, что подсказка помещается слева, относительно окна браузера, а потом вызываем функцию для проверки сверху и снизу
      if(entry.boundingClientRect.top>tooltip.offsetHeight+this.indentFromElement)this.possiblePosition.top=this.checkVerticalCapacity(entry,tooltip,pointerPosition) //Изначально проверяем, что подсказка помещается сверху, относительно окна браузера, а потом вызываем функцию для проверки слева и справа
      if(entry.boundingClientRect.top+entry.target.offsetHeight+tooltip.offsetHeight+this.indentFromElement<entry.rootBounds.height)this.possiblePosition.bottom=this.checkVerticalCapacity(entry,tooltip,pointerPosition) //Изначально проверяем, что подсказка помещается снизу, относительно окна браузера, а потом вызываем функцию для проверки слева и справа
    },
    changerPointerClass(type,pointerPosition){ //Указатели подсказок слева/справа и сверху/снизу имеют разные названия, поэтому их нужно связать, чтобы потом вставлять нужную позицию указателя при смене общей позиции подсказки
      //Пример: подсказки с позициями right-top/right-bottom будут соответствовать подсказкам с позициями top-left/top-right. То есть указатель top=left, а bottom = right
      if(type==='horizontal'){
        if(pointerPosition==='left') return 'top'
        else if(pointerPosition==='right') return 'bottom'
        else return pointerPosition
      } else if(type==='vertical'){
        if(pointerPosition==='top') return 'left'
        else if(pointerPosition==='bottom') return 'right'
        else return pointerPosition
      }
    },
    updatePositionTooltip(entry,tooltip,globalPosition,finallyPointerPosition){ //обновление дефолтной позиции подсказки
      const tooltipClass= globalPosition+'-'+finallyPointerPosition
      tooltip.classList.add(tooltipClass);
      this.determiningBasePosition(entry,tooltip,globalPosition,finallyPointerPosition)
    },
    checkVerticalCapacity(entry,tooltip,pointerPosition){ //Функция определения вместимости слева и справа экрана для подсказок по вертикали (т.е. для подсказок top/bottom)
      let possibleCapacity=false
      const positionX=entry.boundingClientRect.left+entry.target.offsetWidth/2
      let temporaryPointerPosition=this.changerPointerClass('vertical',pointerPosition)
      if(temporaryPointerPosition==='left'&&(positionX+tooltip.offsetWidth-22<entry.rootBounds.width)&&(positionX-22>0)) possibleCapacity=true
      else if(temporaryPointerPosition==='center'&&(positionX+tooltip.offsetWidth/2<entry.rootBounds.width)&&(positionX-tooltip.offsetWidth/2>0))  possibleCapacity=true
      else if(temporaryPointerPosition==='right'&&(positionX+22<entry.rootBounds.width)&&(positionX-tooltip.offsetWidth+22>0))possibleCapacity=true
      return possibleCapacity
    },
    checkHorizontalCapacity(entry,tooltip,pointerPosition){ //Функция определения вместимости сверху и снизу экрана для подсказок по горизонтали (т.е. для подсказок left/right)
      let possibleCapacity=false
      const positionY=entry.boundingClientRect.top+entry.target.offsetHeight/2
      let temporaryPointerPosition=this.changerPointerClass('horizontal',pointerPosition)
      if(temporaryPointerPosition==='top'&&(positionY+tooltip.offsetHeight-16<entry.rootBounds.height)&&(positionY-16>0)) possibleCapacity=true
      else if(temporaryPointerPosition==='center'&&(positionY+tooltip.offsetHeight/2<entry.rootBounds.height)&&(positionY-tooltip.offsetHeight/2>0))  possibleCapacity=true
      else if(temporaryPointerPosition==='bottom'&&(positionY+16<entry.rootBounds.height)&&(positionY-tooltip.offsetHeight+16>0))possibleCapacity=true
      return possibleCapacity
    },
    watchingChangePosition(){
      //Так как событие mouseleave не отрабатывает при скролле, то возможна ситуация, когда пользователь навёлся на ховер-элемент, появилась подсказка, а затем пользователь начал скроллить.
      // В этом случае подсказка не скроется и так как она позиционируется относительно экрана, а не относительно элемента, то подсказка улетит вместе со скроллом. Поэтому нужно скрывать подсказу при любом скролле.
      window.addEventListener('scroll', this.closeOptions, true)
    }
  },
}
</script>

<style lang="scss" scoped>
.tooltip-move, /* применять переход к движущимся элементам */
.tooltip-enter-active,
.tooltip-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.tooltip-enter,
.tooltip-leave-to {
  opacity: 0;
}

.tooltip-wrapper{
  .tooltip-block{
    position: fixed;
    z-index: 9000;
    inset: 0 auto auto -200%; //Выкидываем указатель за пределы экрана. Так как нам сначала нужно отобразить подсказку, затем задать нужные координаты и только потом отобразить.
    .tooltip-container{
      display: flex;
      flex-direction: column;
      .tip-frame{
        position: absolute;
        svg{
          content: "";
          display: block;
        }
      }
      .tip-info{
        box-shadow: 0px 4px 8px 0px rgba(23, 23, 28, 0.05), 0px 0px 4px 0px rgba(23, 23, 28, 0.10);
        padding: 12px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        background: var(--colorBackgroundContent);
        width: 100%;
        gap: 8px;
        .tip-info__header{
          color: var(--colorTextPrimary);
          font-variant-numeric: lining-nums tabular-nums;
          font-size: 14px;
          font-style: normal;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.07px;

          display: flex;
          flex-direction: column;
          .tip-info__header_title{
            font-weight: 600;
          }
          .tip-info__header_description{
            font-weight: 400;
          }
        }
      }
    }
    &.overlay{
      .tooltip-container{
        .tip-info{
          background: var(--colorOverlayDeep);
          .tip-info__header{
            color: var(--colorTextContrastInvariably);
          }
        }
      }
    }
    &.accent{
      .tooltip-container{
        .tip-info{
          background: var(--colorBackgroundAccentTint);
          .tip-info__header{
            color: var(--colorTextContrastInvariably);
          }
        }
      }
    }

    &.bottom-left{
      .tooltip-container{
        .tip-frame{
          bottom: 100%;
          left: 12px;
        }
      }
    }
    &.bottom-center{
      .tooltip-container{
        .tip-frame{
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &.bottom-right{
      .tooltip-container{
        .tip-frame{
          bottom: 100%;
          right: 12px;
          left: unset;
        }
      }
    }


    &.top-left{
      .tooltip-container{
        .tip-frame{
          left: 12px;
          top:100%;
          transform: rotate(180deg);
        }
      }
    }
    &.top-center{
      .tooltip-container{
        .tip-frame{
          top:100%;
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }
    &.top-right{
      .tooltip-container{
        .tip-frame{
          top:100%;
          right: 12px;
          transform: rotate(180deg);
        }
      }
    }


    &.left-top{
      .tooltip-container{
        .tip-frame{
          transform: rotate(90deg) translate(50%, -50%);
          transform-origin: right;
          right: 0;
          top: 12px;
        }
      }
    }
    &.left-center{
      .tooltip-container{
        .tip-frame{
          transform: rotate(90deg) translate(50%, -50%);
          transform-origin: right;
          right: 0;
          top: calc(50% - 3px);
        }
      }
    }
    &.left-bottom{
      .tooltip-container{
        .tip-frame{
          transform: rotate(90deg) translate(50%, -50%);
          transform-origin: right;
          right: 0;
          bottom: 12px;
        }
      }
    }

    &.right-top{
      .tooltip-container{
        .tip-frame{
          transform: rotate(-90deg) translate(-50%, -50%);
          transform-origin: left;
          top: 12px;
        }
      }
    }
    &.right-center{
      .tooltip-container{
        .tip-frame{
          transform: rotate(-90deg) translate(-50%, -50%);
          transform-origin: left;
          top: calc(50% - 3px);
        }
      }
    }
    &.right-bottom{
      .tooltip-container{

        .tip-frame{
          transform: rotate(-90deg) translate(-50%, -50%);
          transform-origin: left;
          bottom: 12px;
        }
      }
    }

  }
}
</style>