export default {
    methods: {
        priceFormat(value) {
            // if(!value) return ''
            var formatter = new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: this.$store.getters.getCurrency.description,
                minimumFractionDigits: 0
            })
            return formatter.format((value || 0)) || 0
        },
    }
}