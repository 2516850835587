<template>
  <div class="object-settings-page">
    <template v-if="!load && !loadSettings">
      <modal-container
          v-if="visibleAddBlock"
          mode="split"
          :title="'Добавление блока'"
          :confirm-text="'Применить'"
          width="600px"
          :touch-device-full-screen="true"
          @confirm-option="addBlock"
          @close-modal="cancelAddBlock"
      >
        <div class="block-add-modal">
          <div class="default-section section-with-padding">
            <form-item
                :title="'Название блока'"
            >
              <default-new-input
                  v-model="newBlock.name"
                  :placeholder="'Введите название блока'"
              />
            </form-item>
            <form-item
                :title="'Название блока'"
            >
              <segmented-control
                  :tabs="['Баннер', 'Галерея','iFrame']"
                  v-model="newBlock.type"
                  size="medium"
                  :stretched="true"
              ></segmented-control>
            </form-item>
          </div>
          <div class="banner-editor">
            <div class="banner-editor__header">
              <div class="title-block-banner">Баннер</div>
            </div>
            <div class="banner-editor__form">
              <div class="info-banner">
                <div class="default-section">
                  <form-item
                      :title="'Заголовок'"
                      :required="true"
                      :notificationError="false"
                      :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                        :notificationError="false"
                        :placeholder="'Введите текст заголовка'"
                    />
                  </form-item>
                  <form-item
                      :title="'Цвет заголовка'"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                    />
                  </form-item>
                </div>
                <div class="default-section">
                  <form-item
                      :title="'Подзаголовок'"
                  >
                    <textarea-component :placeholder="'Квартиры по программе «Выгодно» '"/>
                  </form-item>
                </div>
                <div class="default-section">
                  <form-item
                      :title="'Цвет подзаголовка'"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                    />
                  </form-item>
                  <form-item
                      :title="'Цвет текста'"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                    />
                  </form-item>
                </div>
                <div class="default-section">
                  <form-item
                      :title="'Текст'"
                  >
                    <textarea-component :placeholder="'Доступны на платформе до 1 января 2025 года'"/>
                  </form-item>
                </div>
              </div>
              <div class="separator-editor-banner">
                <div class="separator-editor-banner__line"></div>
              </div>
              <div class="actions-banner">
                <div class="default-section section-with-padding">
                  <form-item
                      :title="'Основная кнопка'"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                        :placeholder="'Согласиться'"
                    />
                  </form-item>
                  <form-item
                      :title="'Ссылка'"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                        :placeholder="'www.itrielt.ru'"
                    />
                  </form-item>
                </div>
                <div class="default-section section-with-padding">
                  <form-item
                      :title="'Дополнительная кнопка'"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                        :placeholder="'Подробнее'"
                    />
                  </form-item>
                  <form-item
                      :title="'Ссылка'"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                        :placeholder="'www.itrielt.ru'"
                    />
                  </form-item>
                </div>
                <div class="close-action">
                  <switch-component
                      :title="'Возможность скрыть баннер'"
                      :title-tooltip="'«Крестик»'"
                      :description-tooltip="'Кнопка в верхнем правом углу, позволяет скрыть баннер. Если обновить страницу, баннер снова отобразится на экране пользователя'"
                      :width-tooltip="292"
                  />
                </div>
              </div>
              <div class="separator-editor-banner">
                <div class="separator-editor-banner__line"></div>
              </div>
              <div class="background-banner">
                <div class="default-section section-with-padding">
                  <form-item
                      :title="'Фон'"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                        :placeholder="'Согласиться'"
                    />
                  </form-item>
                  <form-item
                      :title="'Тип фона'"
                  >
                    <segmented-control
                        :tabs="['Тёмный', 'Светлый']"
                        v-model="newBlock.type"
                        size="medium"
                        :stretched="true"
                    ></segmented-control>
                  </form-item>
                </div>
              </div>
              <div class="separator-editor-banner">
                <div class="separator-editor-banner__line"></div>
              </div>
              <div class="cover-banner">
                <div>
                  <form-item
                      :title="'Тип фона'"
                  >
                    <segmented-control
                        :tabs="[{icon:'/default-icons/block.svg',value:'Нет'},{icon:'/default-icons/account_circle.svg',value:'Профиль'}, {icon:'/default-icons/panorama.svg',value:'Медиа'}, {icon:'/default-icons/add_circle.svg',value:'Иконка'}]"
                        v-model="newBlock.type"
                        size="medium"
                        :stretched="true"
                    ></segmented-control>
                  </form-item>
                </div>
                <div class="default-section section-with-padding">
                  <form-item
                      :title="'Иконка'"
                  >
                    <icons-library-dropdown
                        v-model="newBlock.name"
                    />
                  </form-item>
                  <form-item
                      :title="'Цвет иконки'"
                  >
                    <default-new-input
                        v-model="newBlock.name"
                    />
                  </form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal-container>
      <div class="object-settings-types">
        <default-tabs
            mode="primary"
            size="medium"
            :tabs="tabsTypesObject"
            :padding="true"
            v-model="currentTab"
        />
      </div>
      <div class="object-settings-main">
        <div class="header-functions-table" v-if="false">
          <default-button
              :left-icon="'/default-icons/add_circle.svg'"
              :width="'auto'"
              @click-element="visibleAddBlock=true"
          >Добавить</default-button>
        </div>
        <table class="table table-responsive table-bordered table-hover table-settings">
          <thead>
          <tr>
            <th>#</th>
            <th>{{$t('Значение')}}</th>
            <th style="cursor: pointer" v-for="(name, role) in roles" @click="">
              <div class="settings-choice-wrapper" @click="invertRole(role)">
                <div class="settings-choice__checkbox">
                  <input
                      class="custom-checkbox"
                      :id="'selectedAccess'+role"
                      type="checkbox"
                      v-model="tempCheckRole[role]"
                      @input="invertRole(role)"
                  />
                  <label :for="'selectedAccess'+role"></label>
                </div>
                <div class="settings-choice__text">
                  <label class="settings-choice__text_label"
                         :for="'selectedAccess'+role">{{ name }}</label>
                </div>
              </div>
            </th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <template v-for="section in sections">
            <tr :key="section.key">
              <td>#</td>
              <td :colspan="Object.keys(roles).length+1"><h3>{{$t(section.name)}}</h3></td>
              <td v-if="section.key==='descriptions'">
                <div class="open-new-table">
                  <default-button
                      size="medium"
                      type="outline"
                      :left-icon="'/default-icons/edit.svg'"
                      @click-element="openCharacteristics"
                      width="auto"
                  />
                </div>
              </td>
              <td v-else>
                <div class="open-new-table">
                  <icon-component
                      :icon="'/default-icons/lock.svg'"
                      :color="'var(--colorIconSecondary)'"
                      :size="20"
                  />
                </div>
              </td>
            </tr>
            <template v-if="section.children?.length">
                <tr
                    v-for="elem in section.children"
                    :key="elem.key"
                >
                  <td>
                    <div class="settings-choice__checkbox" >
                      <input
                          class="custom-checkbox"
                          :id="'selectedAccess'+elem.key"
                          type="checkbox"
                          v-model="tempCheckParam[elem.key]"
                          @input="invertParam(elem.key)"
                      />
                      <label :for="'selectedAccess'+elem.key"></label>
                    </div>
                  </td>
                  <td class="param-name">{{$t(elem.name)}}</td>
                  <td v-for="(name, role) in roles" :key="elem.key+role">
                    <div class="settings-choice__checkbox">
                      <input
                          class="custom-checkbox"
                          :id="'selectedAccess'+elem.key+role"
                          type="checkbox"
                          v-model="elem[role]"
                      />
                      <label :for="'selectedAccess'+elem.key+role"></label>
                    </div>
                  </td>
                  <td>
                    <div class="open-new-table">
                      <icon-component
                          :icon="'/default-icons/lock.svg'"
                          :color="'var(--colorIconSecondary)'"
                          :size="20"
                      />
                    </div>
                  </td>
                </tr>
            </template>
          </template>
          </tbody>
        </table>
        <default-button @click-element="saveParams">{{ $t('сохранитьИзменения') }}</default-button>
      </div>
    </template>
    <div class="editor-settings-object-loader" v-else>
      <loader :text="$t('ДанныеЗагружаются')"></loader>
    </div>
  </div>
</template>

<script>
import DefaultTabs from "@/components/redesign/defaultTabs.vue";
import draggable from 'vuedraggable'
import DefaultButton from "@/components/redesign/defaultButton.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import Loader from "@/components/Loader.vue";
import axios from "axios";
import Vue from "vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";
import FormItem from "@/components/redesign/FormItem.vue";
import DefaultNewInput from "@/components/redesign/defaultNewInput.vue";
import SegmentedControl from "@/components/redesign/segmentedControl.vue";
import TextareaComponent from "@/components/redesign/textareaComponent.vue";
import SwitchComponent from "@/components/redesign/switchComponent.vue";
import DefaultNewDropdown from "@/components/redesign/defaultNewDropdown.vue";
import IconsLibraryDropdown from "@/components/redesign/IconsLibraryDropdown.vue";

export default {
  name: "ObjectSettingsEditorProject",
  components: {
    IconsLibraryDropdown,
    DefaultNewDropdown,
    SwitchComponent,
    TextareaComponent,
    SegmentedControl,
    DefaultNewInput, FormItem, ModalContainer, Loader, IconComponent, DefaultButton, DefaultTabs, draggable},
  data() {
    return{
      load:true,
      loadSettings:true,
      tabsTypesObject:[],
      currentTab: null,
      tempCheckRole: {},
      defaultStateRoles:{},
      tempCheckParam: {},
      sections:[],
      visibleAddBlock:false,
      newBlock:{
        name:'',
        type:'Баннер'
      },
      /*currentTypeAddBlock:'Баннер',*/
      dragging: false
    }
  },
  methods:{
    addBlock(){},
    cancelAddBlock(){
      this.visibleAddBlock=false
    },
    setRoles(section) {
      const roles = {}
      for (const role in this.roles) {
        if(section.access?.length)roles[role] = !!(section.access.includes(role));
      }
      return roles
    },
    getAccess(section) {
      const newArr = []
      for (const role in this.roles) {
        const keySection = Object.keys(section)
        if(keySection.includes(role) && section[role] === true) {
          newArr.push(role)
        }
      }
      return newArr
    },
    sectionsSetRoles(sections) {
      for (let section of sections) {
          if(section.children) {
            section.children = section.children.map(el => ({...el, ...this.setRoles(el)}))
          }
      }
      return sections
    },
    filterSections(sections) {
      const newArr = []
      sections.forEach((section,index) => {
        const obj ={
          key: section.key,
          name: section.name,
          index: index
        }
        if(section.children?.length) {
          obj.children = this.filterSections(section.children)
        }
        const access = this.getAccess(section)
        if(access?.length)obj.access=access
        newArr.push(obj)
      });
      return newArr
    },
    saveParams(){
      try {
        const data = this.filterSections(structuredClone(this.sections))
        axios.post('sections/object/save', {
          sections: data
        }, {
          params: {
            typeObject: this.currentTab,
            id: this.$route.params.id
          }
        }).then(()=>{
          this.$emit('successfulSave')
        })
      } catch (error) {
        this.$emit('unsuccessfulSave')
      }
    },
    openCharacteristics(){
      this.$router.push(`/building-characteristics/${this.$route.params.id}/${this.currentTab}`)
    },
    invertRole(role){
      this.tempCheckRole[role] = !this.tempCheckRole[role]
      this.sections=this.sections.map(section=>{
        section.children=section.children.map(el=>{
          el[role]=this.tempCheckRole[role]
          return el
        })
        return section
      })
    },
    invertParam(param){
      this.tempCheckParam[param] = !this.tempCheckParam[param]
      this.sections=this.sections.map(section=>{
        section.children=section.children.map(el=>{
          if(el.key===param){
            Object.keys(this.roles).forEach(role=>{
              el[role]=this.tempCheckParam[param]
            })
          }
          return el
        })
        return section
      })
    },
    checkStateSections(tree){
      let stateRoles = structuredClone(this.defaultStateRoles)
      tree.forEach(node=>{
        if(node.children?.length){
          node.children.forEach(child=>{
            Object.keys(stateRoles).forEach(role=>{
              if(!child[role])stateRoles[role]=false
            })
            let access = this.getAccess(child)
            if(access.hasAll(Object.keys(this.roles))){
              Vue.set(this.tempCheckParam, child.key, true)
            } else {
              Vue.set(this.tempCheckParam, child.key, false)
            }
          })
        }
      })
      this.tempCheckRole=stateRoles
    },
    async initSettings(){
      this.loadSettings = true
      const settings = await axios.get('sections/object/get-all', {
        params: {
          id: this.$route.params.id,
          typeObject: this.currentTab,
        }
      })

      if(settings?.data?.length){
        this.sections = this.sectionsSetRoles(settings.data)
      } else this.sections = this.sectionsSetRoles(structuredClone(this.defaultSections))
      this.loadSettings = false
    }
  },
  watch:{
    currentTab(newValue){
      localStorage.setItem('TypeSettingsObject', JSON.stringify(newValue))
      this.initSettings()
    },
    sections:{
      handler(newValue){
        this.checkStateSections(newValue)
      }, deep:true
    }
  },
  computed:{
    roles(){
      return this.$store.getters.getAllRoles
    },
    defaultSections(){
      return this.$store.getters.getObjectSettings
    },

  },
  async mounted() {
    this.load = true
    this.tabsTypesObject = await axios.get('/complex/get-types-objects?id=' + this.$route.params.id)
        .then(e => e?.data ?? [])
        .catch(() => [])
    let storageType = localStorage.getItem('TypeSettingsObject')&&localStorage.getItem('TypeSettingsObject')!=='undefined'?JSON.parse(localStorage.getItem('TypeSettingsObject')) : null

    this.currentTab = storageType && this.tabsTypesObject.some(el => el === storageType) ?
        storageType :
        this.tabsTypesObject[0]
    for (const role in this.roles) {
      this.defaultStateRoles[role]=true
    }
    this.load = false
  },

}
</script>

<style scoped lang="scss">
.block-add-modal{
  .default-section{
    display: flex;
    gap: 16px;
    &.section-with-padding{
      padding: 0 16px;
    }
  }
  .separator-editor-banner{
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .separator-editor-banner__line{
      height: 1px;
      width: 100%;
      background: var(--colorStrokeSecondary);
    }
  }
  .banner-editor{
    .banner-editor__header{
      padding: 12px 16px 4px;
      .title-block-banner{
        padding: 6px 0;

        color: var(--colorTextPrimary);
        font-variant-numeric: lining-nums tabular-nums;

        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 120% */
      }
    }
    .banner-editor__form{
      .info-banner{
        padding: 0 16px;
      }
    }
  }
}
.header-functions-table{
  display: flex;
  padding: 16px 12px;
  justify-content: space-between;
}
.object-settings-page{
  .object-settings-types{
    padding: 8px 0;
  }
  .table-settings{
    .open-new-table{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .param-name{
      cursor: pointer;
    }
    .settings-choice-wrapper{
      display: flex;
      align-items: center;
      gap: 4px;
      .settings-choice__text{
        cursor: pointer;
        color: var(--colorTextPrimary);
        font-variant-numeric: lining-nums tabular-nums;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.07px;
        .settings-choice__text_label{
          cursor: pointer;
        }
      }
    }
    .settings-choice__checkbox {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      /* для элемента input c type="checkbox" */
      .custom-checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }

      /* для элемента label, связанного с .custom-checkbox */
      .custom-checkbox + label {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* создание в label псевдоэлемента before со следующими стилями */
      .custom-checkbox + label::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        background-repeat: no-repeat;
        background-position: center center;


        background-size: contain;
        background-color: var(--colorComponentCheckboxIcon);
        -webkit-mask: url('/public/my/checkboxDefault.svg') no-repeat center / contain;
        mask: url('/public/my/checkboxDefault.svg') no-repeat center / contain;

      }

      /* стили при наведении курсора на checkbox */
      .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {

      }

      /* стили для активного чекбокса (при нажатии на него) */
      .custom-checkbox:not(:disabled):active + label::before {

      }

      /* стили для чекбокса, находящегося в фокусе */
      .custom-checkbox:focus + label::before {

      }

      /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
      .custom-checkbox:focus:not(:checked) + label::before {

      }

      /* стили для чекбокса, находящегося в состоянии checked */
      .custom-checkbox:checked + label::before {
        background-color: var(--colorComponentCheckboxIconActive);
        -webkit-mask: url('/public/my/checkboxActive.svg') no-repeat center / contain;
        mask: url('/public/my/checkboxActive.svg') no-repeat center / contain;
      }

    }
  }
}
.editor-settings-object-loader{
  padding: 20px 0;
}
</style>