<script>
export default {
  name: "bankCard",
  props: {
    icon: {
      type: String
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
<div :class="[{active}, 'bank-card']">
  <img :src="icon" alt="">
</div>
</template>

<style scoped lang="scss">
.bank-card {
  background: var(--colorBackgroundPrimary);
  cursor: pointer;
  border-radius: 8px;
  padding: 16px;
  height: 48px;
  flex: 1;
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    filter: grayscale(1);
    padding: 4px;
    width: 66px;
    height: 24px;
  }
  &:hover {
    > img {
      filter: grayscale(0);
    }
    box-shadow: 0 8px 16px 0 rgba(23, 23, 28, 0.05), 0 0 8px 0 rgba(30, 30, 36, 0.10);
  }
  &.active {
    > img {
      filter: grayscale(0);
    }
    border: 1px solid var(--colorStrokeAccentInvariably);
    background: var(--colorBackgroundContrastInvariably);
  }
}
</style>