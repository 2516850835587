<template>
  <div class="segmented-control-wrapper" :class="{'stretched':stretched}">
    <div class="segmented-control-container" :class="[size]">
      <template v-if="tabs[0] !== null && typeof tabs[0] === 'object'">
        <div class="segmented-tab" :class="{'active':tab.value === value.value}" v-for="(tab,index) in tabs" :key="index+tab.value" @click="onTabSelected(tab)">
          <div class="segmented-tab__icon" v-if="tab.icon" :style="`mask: url('${tab.icon}') no-repeat center / contain;  -webkit-mask: url('${tab.icon}') no-repeat center / contain;`"></div>
          <div class="segmented-tab__text" v-if="tab.value&&visibleText">{{tab.value}}</div>
        </div>
      </template>
      <template v-else>
        <div class="segmented-tab" :class="{'active': tab === value}" v-for="(tab,index) in tabs" :key="index+tab" @click="onTabSelected(tab)">
          <div class="segmented-tab__text" v-if="tab">{{tab}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * @prop {Array} tabs - Массив двух видов: 1. Массив строк с именами табов 2. Массив объектов, где каждый объект содержит поле icon - ссылка на иконку, поле value - значение таба {icon:'Ссылка',value:'Значение'}
 * @prop value - Текущий таб
 * @prop {Boolean} stretched - Включает/выключает растягивание табов на всю ширину
 * @prop {Boolean} visibleText - Отвечает за отображение значений табов. Работает только для массива объектов с иконками
 * @prop {String} size - содержит следующие размеры:
 * 1.large
 * 2.medium
 * 3.default-size(default)
 */
export default {
  name: "segmentedControl",
  props:{
    size:{
      type:String,
      default:'default-size'
    },
    tabs: {
      type: Array,
      default() {
        return []
      }
    },
    value: {},
    stretched: {
      type: Boolean,
      default: false
    },
    visibleText: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    onTabSelected(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped lang="scss">
.segmented-control-wrapper{
  display: flex;
  .segmented-control-container{
    border-radius: 8px;
    background: var(--colorBackgroundSecondary);

    display: flex;
    padding: 2px;
    gap: 2px;
    .segmented-tab{
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;
      .segmented-tab__icon{
        background-color: var(--colorIconPrimary);
      }
      .segmented-tab__text{
        color: var(--colorTextPrimary);
        text-align: center;
        font-variant-numeric: lining-nums tabular-nums;
        font-style: normal;
      }
      &.active{
        background: var(--colorComponentDropdownListItemBackground);
        box-shadow: 0px 4px 8px 0px rgba(23, 23, 28, 0.05), 0px 0px 4px 0px rgba(23, 23, 28, 0.10);
      }
      &:hover{
        .segmented-tab__icon{
          background-color: var(--colorIconSecondary);
        }
        .segmented-tab__text{
          color: var(--colorTextSecondary);
        }
      }
    }
    &.large{
      .segmented-tab{
        padding: 10px 12px;
        gap: 4px;
        .segmented-tab__icon{
          width: 24px;
          height: 24px;
        }
        .segmented-tab__text{
          padding: 2px 0;

          font-size: 16px;
          font-weight: 500;
          line-height: 20px; /* 125% */
          letter-spacing: 0.024px;
        }
      }
    }
    &.medium, &.default-size {
      .segmented-tab{
        padding: 6px 8px;
        gap: 2px;
        .segmented-tab__icon{
          width: 20px;
          height: 20px;
        }
        .segmented-tab__text{
          padding: 1px 0;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.07px;
        }
      }
    }
    @media (width < 1365px) {
      &.default-size {
        .segmented-tab{
          padding: 10px 12px;
          gap: 4px;
          .segmented-tab__icon{
            width: 24px;
            height: 24px;
          }
          .segmented-tab__text{
            padding: 2px 0;

            font-size: 16px;
            font-weight: 500;
            line-height: 20px; /* 125% */
            letter-spacing: 0.024px;
          }
        }
      }
    }
  }
  &.stretched{
    width: 100%;
    .segmented-control-container{
      width: 100%;
      .segmented-tab{
        flex: 1 0 0;
      }
    }
  }
}
</style>