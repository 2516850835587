import { render, staticRenderFns } from "./RepairsLayout.vue?vue&type=template&id=372c874f&scoped=true"
import script from "./RepairsLayout.vue?vue&type=script&lang=js"
export * from "./RepairsLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "372c874f",
  null
  
)

export default component.exports