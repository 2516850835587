<template>
  <div class="rooms-filter-counter">
    <div :class="'rooms-filter-counter__el '+(options.indexOf(0) !== -1 ? 'active':'')+(selected.indexOf(0) !== -1 ? ' selected':'')" @click="selectOption(0)">
      <span class="rooms-filter-counter__el_min">{{$t('СтудияСокращённо')}}</span>
      <span class="rooms-filter-counter__el_max">{{$t('Студия')}}</span>
    </div>
    <div :class="'rooms-filter-counter__el '+(options.indexOf(1) !== -1 ? 'active':'')+(selected.indexOf(1) !== -1 ? ' selected':'')" @click="selectOption(1)">1</div>
    <div :class="'rooms-filter-counter__el '+(options.indexOf(2) !== -1 ? 'active':'')+(selected.indexOf(2) !== -1 ? ' selected':'')" @click="selectOption(2)">2</div>
    <div :class="'rooms-filter-counter__el '+(options.indexOf(3) !== -1 ? 'active':'')+(selected.indexOf(3) !== -1 ? ' selected':'')" @click="selectOption(3)">3</div>
    <div :class="'rooms-filter-counter__el '+(fromFourRooms ? 'active':'')+(selected.some(el=>el>=4) ? ' selected':'')" @click="selectOption(4)">4+</div>
  </div>
</template>

<script>
export default {
  name: "roomsFilterCounter",
  props:{
    options:{
      type:Array,
      default(){
        return []
      }
    },
    selected:{
      type:Array,
      default(){
        return []
      }
    },
  },
  data(){
    return {
      fromFourRooms:false,
    }
  },
  computed: {

  },
  watch: {
    options: {
      handler: function (after, before) {
        if(after&&after.length) after.map(el=>{
          this.fromFourRooms= el>=4
        })
      },
      deep: true
    },
  },
  methods:{
    selectOption(option){
      if((this.options.indexOf(option) !== -1&&option!==4) || (this.fromFourRooms&&option===4))this.$emit('select-deal',option)
    },
  },
  mounted() {
    if(this.options&&this.options.length) this.options.map(el=>{
      if(el>=4)this.fromFourRooms=true
    })
  }

}
</script>

<style lang="scss">
.rooms-filter-counter{
  display: flex;
  gap: 5px;
  .rooms-filter-counter__el{
    border-radius: 10px;
    border: 1px solid #EAECEE;
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
    color: #E2E8F0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    .rooms-filter-counter__el_min{

    }
    .rooms-filter-counter__el_max{
      display: none;
    }
  }
  .rooms-filter-counter__el.active{
    border-radius: 10px;
    background:  var(--rooms-select-btn);
    color: var(--description-jk);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    transition: background 0.3s;
    &:hover{
      background-color: var(--color-E2E8F0);
    }
  }
  .rooms-filter-counter__el.active.selected{
    transition: all 0.3s;
    background-color: #6F77FE;
    color: var(--main-page-enter-color);
  }
}
@media screen and (max-width: 1919px){
  .rooms-filter-counter{
    .rooms-filter-counter__el{
      .rooms-filter-counter__el_min{
        display: none;
      }
      .rooms-filter-counter__el_max{
        display: inline;
      }
    }
  }
}
@media screen and (max-width: 1000px){
  .rooms-filter-counter{
    .rooms-filter-counter__el{
      .rooms-filter-counter__el_min{
        display: inline;
      }
      .rooms-filter-counter__el_max{
        display: none;
      }
    }
  }
}
@media screen and (max-width: 560px){
  .rooms-filter-counter{
    .rooms-filter-counter__el{
      .rooms-filter-counter__el_min{
        display: none;
      }
      .rooms-filter-counter__el_max{
        display: inline;
      }
    }
  }
}
</style>